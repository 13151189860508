












import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { AccordionItem } from '../../../dsl/atoms/Accordion'
import { HeadingFormData } from '../../partials'

import { DescriptionListData, DescriptionListModule } from '../DescriptionList.contracts'
import DescriptionListContent from './partials/DescriptionListContent.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DescriptionListModuleUi>({
  name: 'DescriptionListModuleUi',
  components: { DescriptionListContent }
})
export class DescriptionListModuleUi extends AbstractModuleUi<DescriptionListModule> {
  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && !!this.data.content.data
  }

  public get heading (): HeadingFormData | undefined {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  public get listData (): DescriptionListData[] {
    return this.data.content.data
  }

  public get isCollapsible (): boolean {
    return this.data.content.isCollapsible
  }

  public get collapsibleItem (): AccordionItem[] {
    return [
      {
        id: 'description-list',
        label: this.heading?.content ?? this.$t('modules.DescriptionList.defaultHeading').toString(),
        isOpen: false,
        content: {
          component: DescriptionListContent,
          props: { listData: this.listData }
        }
      }
    ]
  }
}

export default DescriptionListModuleUi
