











import { Component, Prop, Vue } from 'vue-property-decorator'

import { DescriptionListData } from '../../DescriptionList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DescriptionListContent>({ name: 'DescriptionListContent' })
export class DescriptionListContent extends Vue {
  @Prop({ type: Array, required: false })
  public readonly listData!: DescriptionListData[]
}

export default DescriptionListContent
